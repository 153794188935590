import React, { useState } from 'react'

import {
  Button,
  Buttons,
  Heading,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Notifications,
  Paragraph,
  Tab,
  Tabs
} from '@te-digi/styleguide'

const PatternExampleEsikatsele = () => {
  const [isModalOpen1, setIsModalOpen1] = useState(false)

  const onModalOpenClick1 = () => setIsModalOpen1(isModalOpen => !isModalOpen)
  const onModalClose1 = () => setIsModalOpen1(false)

  return (
    <>
      <Button
        color="primary"
        onClick={onModalOpenClick1}
        variant="outline"
      >
        Esikatsele
      </Button>
      <Modal
        ariaLabelledby="modal-heading"
        isOpen={isModalOpen1}
        onRequestClose={onModalClose1}
        size="lg"
      >
        <ModalHeader>
          <Heading
            id="modal-heading"
            noMargin
            size={4}
          >
            Esikatselu
          </Heading>
        </ModalHeader>
        <ModalBody>
          <Tabs ariaLabelledby="heading-id">
            <Tab active>Suomeksi</Tab>
            <Tab>Ruotsiksi</Tab>
            <Tab>Englanniksi</Tab>
          </Tabs>
          <Paragraph>
            Lomakkeelle syötetyt tiedot katselunäkymässä käytetyllä muotoilulla,
            valitun tabin kielellä.
          </Paragraph>
        </ModalBody>
        <ModalFooter>
          <Buttons
            align="right"
            noMargin
          >
            <Button
              onClick={onModalClose1}
              variant="outline"
            >
              Sulje
            </Button>
          </Buttons>
        </ModalFooter>
      </Modal>
      <Notifications />
    </>
  )
}

export { PatternExampleEsikatsele }
