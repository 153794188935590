import React from 'react'

import {
  Bullet,
  Button,
  Buttons,
  Columns,
  Divider,
  FormGroup,
  FormLayout,
  FormLayoutItem,
  Heading,
  List,
  ListItem,
  Main,
  Message,
  Paragraph,
  Spacer,
  Textarea,
  ThumbDownIcon,
  ThumbUpIcon,
  XIcon
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Content } from '../../components/Content'
import { NoteItem } from '../../components/NoteItem'
import { Section } from '../../components/Section'
import { SectionHeading } from '../../components/SectionHeading'
import { ZoomedExample } from '../../components/ZoomedExample'
import { Link } from '../../components/Link'
import { Playground } from '../../components/Playground'
import { PatternExampleEsikatsele } from '../../examples/react/PatternExampleEsikatsele'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content heading="Esikatsele -toiminto">
    <Playground
      enableOverflow
      example={PatternExampleEsikatsele}
    />
    <Section title="Käyttötarkoitukset">
      <Message attentionColor="success">
        <Heading
          icon={
            <ThumbUpIcon
              color="success"
              size="lg"
            />
          }
          level={3}
          size={5}
        >
          Käytetään
        </Heading>
        <List
          noMargin
          variant="unordered"
        >
          <ListItem>
            <strong>Lomakkeilla</strong>, aina kun lomakkeen päätoiminto on{' '}
            <Link page="Julkaise-toiminto" />
          </ListItem>
        </List>
      </Message>
      <Message attentionColor="danger">
        <Heading
          icon={
            <ThumbDownIcon
              color="danger"
              size="lg"
            />
          }
          level={3}
          size={5}
        >
          Ei käytetä
        </Heading>
        <List
          noMargin
          variant="unordered"
        >
          <ListItem>
            <strong>Lomakkeilla</strong>, jos tietoja ei julkaista yleisesti
            nähtäviksi.
          </ListItem>
          <ListItem>
            <strong>Katselunäkymissä</strong>
          </ListItem>
          <ListItem>
            <strong>Käsittelynäkymissä</strong>
          </ListItem>
        </List>
      </Message>
    </Section>
    <Section title="Periaatteet">
      <Heading
        icon={
          <Bullet
            color="danger"
            icon={
              <ThumbDownIcon
                color="white"
                size="md"
              />
            }
            size="lg"
          />
        }
        level={3}
        size={5}
      >
        Tietojen muokkaaminen: ei
      </Heading>
      <List variant="unordered">
        <ListItem>
          <strong>Käyttäjä voi ei muokata tietoja esikatselumodaalissa</strong>.
          Tietojen muokkaus tapahtuu esikatseltavalla lomakkeella.
        </ListItem>
      </List>
      <Divider />
      <Heading
        icon={
          <Bullet
            color="danger"
            icon={
              <ThumbDownIcon
                color="white"
                size="md"
              />
            }
            size="lg"
          />
        }
        level={3}
        size={5}
      >
        Tietojen validointi: ei
      </Heading>
      <List variant="unordered">
        <ListItem>
          Lomakkeelle syötettyjä tietoja <strong>ei validoida</strong> toiminnon
          suorittamisen yhteydessä
        </ListItem>
      </List>
      <Divider />
      <Heading
        icon={
          <Bullet
            color="danger"
            icon={
              <ThumbDownIcon
                color="white"
                size="md"
              />
            }
            size="lg"
          />
        }
        level={3}
        size={5}
      >
        Toiminnon varmistus: ei
      </Heading>
      <List variant="unordered">
        <ListItem>
          Toiminnon suorittamista <strong>ei varmisteta</strong> käyttäjältä.
        </ListItem>
      </List>
      <Divider />
      <Heading
        icon={
          <Bullet
            color="danger"
            icon={
              <ThumbDownIcon
                color="white"
                size="md"
              />
            }
            size="lg"
          />
        }
        level={3}
        size={5}
      >
        Palaute toiminnon onnistumisesta: ei
      </Heading>
      <List variant="unordered">
        <ListItem>
          Toiminnon suorittamisesta <strong>ei ilmoiteta</strong> käyttäjälle
          erikseen.
        </ListItem>
      </List>
      <Divider />
      <Heading
        icon={
          <Bullet
            color="danger"
            icon={
              <ThumbDownIcon
                color="white"
                size="md"
              />
            }
            size="lg"
          />
        }
        level={3}
        size={5}
      >
        Palaute toiminnon epäonnistumisesta: ei
      </Heading>
      <List
        noMargin
        variant="unordered"
      >
        <ListItem>
          Toiminnon epäonnistumisesta <strong>ei ilmoiteta</strong> käyttäjälle
          erikseen.
        </ListItem>
      </List>
      <Divider />
      <Heading
        level={3}
        size={5}
      >
        Liittyvät toiminnot
      </Heading>
      <List variant="unordered">
        <ListItem>
          Toimintoa käytetään lisätoimintona{' '}
          <strong>
            vain, kun lomakkeen päätoiminto on <Link page="Julkaise-toiminto" />
          </strong>
          .
        </ListItem>
      </List>
    </Section>
    <Section title="Rakenne ja toiminnallisuus">
      <Playground
        enableOverflow
        example={PatternExampleEsikatsele}
      />
      <NoteItem
        bullet="1"
        title={
          <>
            Painike (
            <Code>
              <Link page="Button" />, variant='outline', color='Primary'
            </Code>
            )
          </>
        }
      >
        <Paragraph>
          Painikkkesta avautuu esikatselumodaali (
          <Code>
            <Link page="Modal" />
          </Code>
          ), josta käyttäjä voi tarkistaa miltä julkaistavien tietojen
          lopullinen visuaalinen muotoilu näyttää katselunäkymässä (esim.
          työpaikkailmoitus siinä muodossa kuin se näytetään Työmarkkinatorin
          työpaikkahaussa).
        </Paragraph>
      </NoteItem>
      <NoteItem
        bullet="2"
        title={
          <>
            Esikatselumodaali (
            <Code>
              <Link page="Modal" />
            </Code>
            )
          </>
        }
      >
        <List variant="unordered">
          <ListItem>
            <strong>Otsikko</strong>
            <List
              noMargin
              variant="unordered"
            >
              <ListItem>
                Oletuksena otsikossa täsmennetään esikatseltavan tiedon tyyppi.
                Esimerkiksi: &quot;Työpaikkailmoituksen esikatselu&quot;
              </ListItem>
              <ListItem>
                Geneeristä muotoilua &quot;Esikatselu&quot; käytetään, jos
                tiedon tyyppiä ei ole mahdollista täsmentää.
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            <strong>Kieliversiovalinta</strong> (
            <Code>
              <Link page="Tabs" />
            </Code>
            )
            <List
              noMargin
              variant="unordered"
            >
              <ListItem>
                Jos tietoja on syötetty lomakkeelle eri kielillä, esitetään
                kieliversiot modaalissa tabeina.
              </ListItem>
              <ListItem>
                Kielen välilehdellä kaikki tiedot, mukaan lukien rakenteiset
                tiedot, kieliversioidut tiedot ja labelit näytetään siinä
                muodossa, jossa ne näkyvät käyttäjälle kun kyseinen kieli on
                valittu käyttöliittymän kieleksi.
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            <strong>Esikatseltavat tiedot</strong>
            <List
              noMargin
              variant="unordered"
            >
              <ListItem>
                Lomakkeelle syötetyt tiedot aseteltuna saman visuaalinen
                muotoilun mukaisesti, jota käytetään lopullisessa
                katselunäkymässä.
              </ListItem>
              <ListItem>
                Esimerkiksi jos lomakekenttien tietoja on katselunäkymässä
                esitetty{' '}
                <Code>
                  <Link page="Detail" />
                </Code>
                -komponenteilla tai ryhmitelty palstoihin, näitä samoja
                muotoiluja käytetään myös esikatselussa.
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            <Paragraph noMargin>
              <strong>Sulje</strong>-painike (
              <Code>
                <Link page="Button" />, variant='outline'
              </Code>
              ).
            </Paragraph>
            <List
              noMargin
              variant="unordered"
            >
              <ListItem>
                Sulkee dialogin ja palauttaa käyttäjän näkymään jossa hän painoi
                toimintopainiketta.
              </ListItem>
            </List>
          </ListItem>
        </List>
      </NoteItem>
    </Section>
    <Section title="Sijoittuminen näkymässä">
      <List variant="unordered">
        <ListItem>
          Painike sijoitetaan aina Julkaise-painikkeen välittömään läheisyyteen.
        </ListItem>
        <ListItem>
          Sijoittelusta lomakkeella tarkemmin{' '}
          <Link page="Lomakkeen toiminnot" /> -patternissa.
        </ListItem>
      </List>
      <ZoomedExample>
        <Columns layout="single">
          <Main>
            <FormLayout>
              <FormLayoutItem>
                <FormGroup noMargin>
                  <Textarea label="Lomakekenttä" />
                </FormGroup>
              </FormLayoutItem>
            </FormLayout>
            <Spacer marginTop="xl">
              <Buttons align="center">
                <Button
                  iconLeft={<XIcon />}
                  variant="plain"
                >
                  Keskeytä
                </Button>
                <Button
                  color="primary"
                  variant="outline"
                >
                  Esikatsele
                </Button>
                <Button color="secondary">Julkaise</Button>
              </Buttons>
            </Spacer>
          </Main>
        </Columns>
      </ZoomedExample>
    </Section>
    <Section title="Esimerkkejä käyttötapauksista">
      <SectionHeading>Työmarkkinatorilla</SectionHeading>
      <List variant="unordered">
        <ListItem>
          Työnantaja-asiakas, Työpaikkailmoitukset: Lisää uusi työpaikkailmoitus
          -lomake
        </ListItem>
        <ListItem>
          Yhteistyökumppani, Palveluni: Lisää uusi palvelu -lomake
        </ListItem>
      </List>
      <SectionHeading>Asiantuntijan työmarkkinatorilla</SectionHeading>
      <List variant="unordered">
        <ListItem>Palvelut: Lisää uusi palvelu -lomake</ListItem>
      </List>
    </Section>
  </Content>
)

export default Page
